import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ppmLogo from "../assets/logo.svg";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";

import { useAuth } from "../hooks/useAuth";
import { useKeycloak } from "@react-keycloak/web";

function PpmNav() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { keycloak, initialized } = useKeycloak();

  const { clearCookies } = useAuth();
  const handleLogout = () => {
    keycloak.logout();
    clearCookies();
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [initialized, keycloak]);
  return (
    <nav
      className="navbar navbar-dark mb-5 p-3"
      style={{ backgroundColor: "#1d3b4e" }}
    >
      <Link to={"/"}>
        <img src={ppmLogo} alt="logo Peppermint" />
      </Link>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          id="fade-button"
          className="text-light"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => navigate("/change-password")}>
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText>Change password</ListItemText>
          </MenuItem>
        </Menu>
        {/* Logout */}
        <Box
          onClick={() => {
            handleLogout();
          }}
          className="btn btn-outline-light d-flex item-center ml-3"
        >
          <LogoutIcon />
          <Box sx={{ ml: "8px" }}>Logout</Box>
        </Box>
      </Box>
    </nav>
  );
}
export default PpmNav;
