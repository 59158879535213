import React, { useState } from "react";

import {
  FormControl,
  FormHelperText,
  Box,
  TextField,
  Typography,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationSchema } from "../../constants/schema";
import axios from "axios";

import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../hooks/useAuth";

const schema = yup
  .object({
    email: validationSchema.required_email,
  })
  .required();

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate and submit form data
    handleSubmit(onSubmit)();
  };

  const { forgetUserPassword } = useAuth();
  const onSubmit = (data) => {
    setLoading(true);
    setSent(false);
    forgetUserPassword({
      email: data.email,
    })
      .then((res) => {
        if (res) setSent(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" color="primary">
          Forgot password
        </Typography>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormControl fullWidth variant="standard" error={errors.email}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email")}
            />
            {errors.email && (
              <FormHelperText>{errors.email?.message}</FormHelperText>
            )}
          </FormControl>
          {sent && (
            <Typography color="primary" fontWeight={300}>
              A link will be sent to your e-mail to be able to <br /> reset your
              password
            </Typography>
          )}
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
