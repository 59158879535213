import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Cookies from "universal-cookie";
import useAxios from "./useAxios";
import axios from "axios";

const cookies = new Cookies();
const expirationDate = new Date("2100-01-01T00:00:00Z");

const useAuth = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const refreshToken = async () => {
    const refreshToken = cookies.get("refreshToken");
    if (!refreshToken) {
      logout();
      throw new Error("No refresh token available");
    }
    try {
      const res = await axios.post(
        process.env.REACT_APP_IAM_URL + "/auth/refreshToken",
        {
          refreshToken,
        }
      );
      const newAccessToken = res.data.accessToken;
      const newRefreshToken = res.data.refreshToken;
      cookies.set("accessToken", newAccessToken, {
        expires: expirationDate,
      });
      cookies.set("refreshToken", newRefreshToken, {
        expires: expirationDate,
      });
      return newAccessToken;
    } catch (error) {
      logout();
      throw error;
    }
  };
  const logout = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_IAM_URL + "/auth/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${cookies.get("accessToken")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      cookies.remove("accessToken");
      cookies.remove("refreshToken");
      cookies.remove("user_role");
      // navigate("/login");
    }
  };

  const clearCookies = () => {
    cookies.remove("accessToken");
    cookies.remove("refreshToken");
    cookies.remove("user_role");
  };

  const axiosInstance = useAxios(enqueueSnackbar, logout, refreshToken);

  const login = async (data) => {
    try {
      const res = await axiosInstance.post("/auth/loginUser", data);
      const accessToken = res.data.accessToken;
      const refreshToken = res.data.refreshToken;
      cookies.set("accessToken", accessToken, {
        expires: expirationDate,
      });
      cookies.set("refreshToken", refreshToken, {
        expires: expirationDate,
      });

      const currentLoginUser = await axiosInstance.get(
        "/auth/currentLoginUser"
      );
      cookies.set("user_role", currentLoginUser.data.platform, {
        expires: expirationDate,
      });

      enqueueSnackbar("Login successful", {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Invalid email or password", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const getCurrentUser = async () => {
    try {
      await verifyUserData();
      const res = await axiosInstance.get("/auth/currentLoginUser");
      return res.data;
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
      throw error;
    }
  };

  const changePassword = async (data) => {
    try {
      await axiosInstance.put("/auth/changePassword", data);
      enqueueSnackbar("Password changed successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      return true;
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return false;
    }
  };

  const forgetUserPassword = async (data) => {
    try {
      await axios.post(
        process.env.REACT_APP_IAM_URL + "/auth/forgetUserPassword",
        data
      );
      enqueueSnackbar("Reset password link sent to your email", {
        variant: "success",
        autoHideDuration: 3000,
      });
      return true;
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return false;
    }
  };

  const resetPassword = async (resettoken, password) => {
    try {
      await axios.post(
        process.env.REACT_APP_IAM_URL + `/auth/resetUserPassword/${resettoken}`,
        {
          password,
        }
      );
      enqueueSnackbar("Password reset successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      return true;
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return false;
    }
  };

  const verifyUserData = async () => {
    try {
      await axiosInstance.get("/auth/verifyUserData");
      return true;
    } catch (error) {
      return false;
    }
  };
  return {
    login,
    logout,
    getCurrentUser,
    changePassword,
    forgetUserPassword,
    resetPassword,
    verifyUserData,
    clearCookies,
  };
};

export { useAuth };
