import React, { useEffect, useState } from 'react'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
} from '@mui/material'
import PpmNav from '../../components/PpmNav'

function ReplayPage() {
  const [seasonSelected, setSeasonSelected] = useState(1)
  const [episodeSelected, setEpisodeSelected] = useState(1)
  const [selectedSection, setSelectedSection] = useState(1)
  const [selectedUrl, setSelectedUrl] = useState(
    'https://www.dropbox.com/s/p4rig4ddosmqtuu/2305-Training%20SM-PO%20-%20S1E1%20-%20Chiffrage%20et%20planification%20-%20Framework%20PepperMint.mp4'
  )

  const replays = [
    {section: 'Product Management Training', contents: [
      {
        season: 1,
        episodes: [
          {
            episode: 1,
            title: 'Chiffrage et planification',
            url: 'https://www.dropbox.com/s/za8xmz0e2gytapd/2305-Training%20SM-PO%20-%20S1E1%20-%20Chiffrage%20et%20planification%20-%20Framework%20PepperMint.mp4',
          },
          {
            episode: 2,
            title: 'Les livrables du cadrage',
            url: 'https://www.dropbox.com/s/37pnh81hlwkrtf4/2305-Training%20SM-PO%20-%20S1E2%20-%20Les%20livrables%20du%20cadrage%20-%20Framework%20PepperMint.mp4',
          },
          {
            episode: 3,
            title: 'Costing - pilotage - Contrat',
            url: 'https://www.dropbox.com/s/k4rcvnr4s6b8wdp/2306-Training%20SM-PO%20-%20S1E3%20-%20Costing%20-%20pilotage%20-%20Contrat%20-%20Framework%20PepperMint.mp4',
          },
          {
            episode: 4,
            title: 'Types engagement, périmètre PO vs SM',
            url: 'https://www.dropbox.com/s/p8dtfyu52x4oujo/2307-Training%20SM-PO%20-%20S1E4%20-%20Types%20engagement%2C%20périmètre%20PO%20vs%20SM.mp4',
          },
        ],
      }
    ]},
    {
      section: 'Cybersecurity Training', contents: [
      {
        season: 1,
        episodes: [
          {
            episode: 1,
            title: 'E-learning cyber sécurité 2023',
            url: 'https://www.dropbox.com/s/iaj22gxrsde6f0r/E-learning%20cyber%20sécurité%202023%20.mp4',
          },
        ],
      }
    ]},
    {
      section: 'Delivery Framework Training', contents: [
      {
        season: 1,
        episodes: [
          {
            episode: 1,
            title: 'Janvier 2023 - 🇫🇷 Équipe France - 🗣️ Fabien (2h)',
            url: 'https://www.dropbox.com/s/2tmhy2r9g7h8cu7/Formation%20Framework%20Delivery%20PepperMint%20-2023-01-25-FR.mp4',
          },
          {
            episode: 2,
            title: 'Décembre 2023 - 🇹🇳 Équipe Tunisie - 🗣️ Moise (1h23)',
            url: 'https://www.dropbox.com/scl/fi/6mah88g8yb9ktk1rj0ae4/Placeholder-Workshop-Framework-PepperMint-2023-12.mp4?rlkey=t5u45jjlzzj8kqxywv7qa8zd3&dl=0',
            useDl: true
          },
        ],
      }
    ]},
  ]
  const onSelectEpisode = (episode, section = 1) => {
    setSelectedSection(section)
    setEpisodeSelected(episode.episode)
    if (episode?.useDl) {
      const k = episode.url.replace('www.dropbox.com', 'dl.dropbox.com')
      setSelectedUrl(k)
      return
    } else {
      setSelectedUrl(episode.url + '?raw=1')
    }
  }
  useEffect(() => {
    // This code will run only once when the component mounts
    onSelectEpisode(replays[0].contents[0].episodes[0], 1)
  }, []); 
  return (
    <>
      <PpmNav />
      <Stack direction={'row'} style={{ width: '100%', height: '80vh' }}>
        <Stack flexWrap="wrap"  direction={'row'}>
        {replays.map((section, index) => {
          return section.contents.map((season) => {
            return (
              <List
                key={`${season.season}-season`}
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                  flexWrap: 'wrap'
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader" sx={{ fontWeight: 'bold', color: 'black'}}>
                    {section.section}
                  </ListSubheader>
                }
              >
                <ListItemButton disabled>
                  <ListItemText primary={`Season ${season.season}`} />
                </ListItemButton>
                <Collapse
                  in={seasonSelected === season.season}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {season.episodes.map((episode) => {
                      return (
                        <ListItemButton
                          key={`${episode.episode}-episode`}	
                          sx={{ pl: 4 }}
                          onClick={() => onSelectEpisode(episode, index + 1)}
                        >
                          <ListItemText
                            primary={`Episode ${episode.episode}${episode.title? ':': '' } ${episode.title}`}
                            style={
                              episode.episode === episodeSelected && selectedSection === index + 1
                                ? { textDecoration: 'underline', fontWeight: 'bold' }
                                : {}
                            }
                          />
                          <ListItemIcon>
                            <a href={`${episode.url}?dl=0`} target="blank">
                              <i className="fa-solid fa-arrow-up-right-from-square" />
                            </a>
                          </ListItemIcon>
                        </ListItemButton>
                      )
                    })}
                  </List>
                </Collapse>
              </List>
            )
          })})}
        </Stack>
        
        <iframe
          title='replay'
          src={`${selectedUrl}`}
          height="100%"
          width="80%"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </Stack>
    </>
  )
}
export default ReplayPage
