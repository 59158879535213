import axios from "axios";
import { getCookie } from "../utils/utils";

const useAxios = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  // Interceptor to add access token to requests
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = getCookie("accessToken");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // // Interceptor to handle 401 errors and refresh token
  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const originalRequest = error.config;

  //     if (error.response.status === 401 && !originalRequest._retry) {
  //       originalRequest._retry = true;

  //       if (!isRefreshing) {
  //         isRefreshing = true;

  //         try {
  //           const newAccessToken = await refreshToken();
  //           processQueue(null, newAccessToken);
  //           originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
  //           return axiosInstance(originalRequest);
  //         } catch (err) {
  //           processQueue(err, null);
  //           logout();
  //           throw err;
  //         } finally {
  //           isRefreshing = false;
  //         }
  //       } else {
  //         return new Promise((resolve, reject) => {
  //           failedQueue.push({ resolve, reject });
  //         })
  //           .then((token) => {
  //             originalRequest.headers.Authorization = `Bearer ${token}`;
  //             return axiosInstance(originalRequest);
  //           })
  //           .catch((err) => {
  //             return Promise.reject(err);
  //           });
  //       }
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  return axiosInstance;
};

export default useAxios;
