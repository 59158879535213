import React, { useEffect, useState } from "react";
import PlatformCard from "../components/PlatformCard";
import PpmNav from "../components/PpmNav";
import Cookies from "universal-cookie";
import { useAuth } from "../hooks/useAuth";
import { useKeycloak } from "@react-keycloak/web";

function Home() {
  const [userRoleData, setUserRoleData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { getCurrentUser } = useAuth();
  const { initialized, keycloak } = useKeycloak();
  useEffect(() => {
    if (!initialized) return;
    if (!keycloak.authenticated) {
      keycloak.login();
    }
    setIsLoading(true);

    getCurrentUser()
      .then((data) => {
        if (data) setUserRoleData(data?.platform);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [initialized, keycloak.authenticated]);

  useEffect(() => {
    if (userRoleData) {
      setIsLoading(false);
    }
  }, [userRoleData]);
  const platforms = [
    {
      url: process.env.REACT_APP_AL_URL,
      icon: "fa-sharp fa-solid fa-calendar",
      title: "Allocation",
      desc: "Peppermint resource allocation platform.",
      accessibility:
        userRoleData?.allocation &&
        userRoleData?.allocation.toLowerCase() !== "na",
    },
    {
      url: process.env.REACT_APP_PF_URL,
      icon: "fa-sharp fa-solid fa-dollar-sign",
      title: "Profitability",
      desc: "Peppermint profitability management platform.",
      accessibility:
        userRoleData?.profitability &&
        userRoleData?.profitability.toLowerCase() !== "na",
    },
    {
      url: process.env.REACT_APP_SK_URL,
      icon: "fa-sharp fa-solid fa-people-group",
      title: "Skill Platform",
      desc: "Peppermint skills management platform.",
      accessibility:
        userRoleData?.skills && userRoleData?.skills.toLowerCase() !== "na",
    },
    {
      url: "https://docs.google.com/forms/d/e/1FAIpQLSea_W4ybwKfix-iEkrXVIxWqXZjPXRyyqMQjJcBh4JCGTNrcg/viewform",
      icon: "fa-sharp fa-solid fa-shield",
      title: "Security incident",
      desc: "Peppermint security incident form.",
      accessibility:
        userRoleData?.securityIncident &&
        userRoleData?.securityIncident.toLowerCase() !== "na",
    },
    {
      url: process.env.REACT_APP_MB_URL,
      icon: "fa-sharp fa-solid fa fa-tachometer",
      title: "Maturity Barometer",
      desc: "Peppermint Maturity Barometer platform.",
      accessibility:
        userRoleData?.maturityBarometer &&
        userRoleData?.maturityBarometer.toLowerCase() !== "na",
    },
    {
      url: process.env.REACT_APP_ES_URL,
      icon: "fa-solid fa-sailboat",
      title: "Efficient Sale",
      desc: "Peppermint Efficient Sale System.",
      accessibility:
        userRoleData?.efficientSales &&
        userRoleData?.efficientSales.toLowerCase() !== "na",
    },
    {
      url: "/elearning",
      icon: "fa-solid fa-desktop",
      title: "E-learning",
      desc: "Elearning Resources and Certification",
      accessibility:
        userRoleData?.elearning &&
        userRoleData?.elearning.toLowerCase() !== "na",
    },
    {
      url: process.env.REACT_APP_SP_URL,
      icon: "fa-solid fa-truck-field",
      title: "Supplier Platform",
      desc: "Supplier Platform.",
      accessibility:
        userRoleData?.freelance &&
        userRoleData?.freelance.toLowerCase() !== "na",
    },
    {
      url: process.env.REACT_APP_QU_URL,
      icon: "fa-sharp fa-solid fa fa-tachometer",
      title: "Quality Control",
      desc: "In Progress...",
      accessibility:
        userRoleData?.maturityBarometer &&
        userRoleData?.maturityBarometer.toLowerCase() !== "na",
    },
  ];

  const isHasAccessToAnyPlatforms = () => {
    return platforms.some((platform) => platform?.accessibility);
  };

  return (
    <>
      <PpmNav />
      <div
        className="d-flex flex-wrap justify-content-around"
        style={{ fontSize: "20px" }}
      >
        {isLoading && (
          <div>
            <i className="fa fa-circle-notch fa-spin"></i> Loading...
          </div>
        )}
        {!isLoading &&
          (isHasAccessToAnyPlatforms(userRoleData) ? (
            platforms.map(
              (x) =>
                x.accessibility &&
                x.accessibility != "na" && (
                  <PlatformCard
                    key={`${x.title}`}
                    url={x.url}
                    title={x.title}
                    icon={x.icon}
                    description={x.desc}
                    accessibility={x.accessibility}
                  />
                )
            )
          ) : (
            <div className="no-access-message">
              You don't have access to any platforms. Please contact the
              administrator.
            </div>
          ))}
      </div>
    </>
  );
}

export default Home;
