import React, { useEffect } from "react";
import { BrowserRouter, useNavigate, useRoutes } from "react-router-dom";

import Home from "./pages/Home";
import Elearning from "./pages/Elearning";
import ReplayPage from "./pages/Elearning/Replays";
import Login from "./pages/Login";

import defautTheme from "./theme/themes";
import { Alert, ThemeProvider } from "@mui/material";

import { SnackbarProvider } from "notistack";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import Certificate from "./pages/Certificate";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloakAuthClient from "./keycloak";
import Cookies from "universal-cookie";

const MainView = () => {
  const routes = useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/",
      element: <Home />,
      index: true,
    },
    {
      path: "/elearning",
      children: [
        {
          path: "",
          element: <Elearning />,
        },
      ],
    },
    {
      path: "/replays",
      children: [
        {
          path: "",
          element: <ReplayPage />,
        },
      ],
    },
    {
      path: "/certificate",
      children: [
        {
          path: "",
          element: <Certificate />,
        },
      ],
    },
    { path: "*", element: <div>Not found</div> },
  ]);
  return routes;
};

const eventLogger = (event, error) => {
  console.log("onKeycloakEvent", event, error);
};

function App() {
  const cookies = new Cookies();
  const handleOnToken = (token) => {
    console.log("onKeycloakTokens", token);
    cookies.set("accessToken", token.token);
    cookies.set("refreshToken", token.refreshToken);
  };
  return (
    <ReactKeycloakProvider
      authClient={keycloakAuthClient}
      onEvent={eventLogger}
      onTokens={handleOnToken}
      autoRefreshToken={true}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false,
      }}
    >
      <ThemeProvider theme={defautTheme}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <MainView />
            {/* <Middleware /> */}
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}
export default App;
